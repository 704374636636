/* Customize your main colors in :root variables */
:root {
  --main-background-color: #000000;
  --card-background-color: #000000;
  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("img/background.png");
  background-size: cover;
  font-family: 'Roboto', cursive;
  font-size: 1.1em;
}
	

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



